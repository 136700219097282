import * as React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* ratio 16x9 */
  width: 100%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const Video = ({ url, title, ...props }) => {
  if (!url) {
    return <></>
  }

  const id = url.split("/").pop()
  const link = url.includes("vimeo")
    ? `https://player.vimeo.com/video/${id}`
    : url.includes("youtube")
    ? `https://www.youtube.com/embed/${id}`
    : url

  return (
    <Wrapper {...props}>
      <iframe
        src={link}
        title={`${title} - embedded video`}
        width="640"
        height="360"
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
      ></iframe>
    </Wrapper>
  )
}

export default Video
